<template>
  <b-container fluid="true" class="wrap">
    <NavBar :navSelIndex="6"></NavBar>
    <b-row>
      <b-col xs="8" sm="7" md="6" lg="6" xl="5" class="title-wrap">
        <div class="page-title">
          <img class="img-fluid w-100 d-block" src="@/../public/icon/vipPrivilege/page-title@2x.png" alt="">
        </div>
      </b-col>
    </b-row>
    <b-row class="privilege-item-wrap">
      <b-col xs="6" sm="6" md="3" lg="3" xl="1" v-for="(item, index) in iconArr" :key="index">
        <div class="item-wrap">
          <div class="item-icon">
            <img :src="item.icon" alt="icon">
          </div>
          <p>{{item.iconTitle}}</p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="4" lg="3" xl="2" class="join-wrap">
        <div class="join-vip"><!--@click="joinVip"-->
          <img src="@/../public/icon/vipPrivilege/join-vip-btn-bg@2x.png" alt="">
          <span>{{$t("vipPrivilegeJoinVip")}}</span>
        </div>
      </b-col>
    </b-row>
    <div class="vip-type" v-if="token != null && token != 'null' && userId != 0">
      <b-row class="vip-type-wrap">
        <b-col class="vip-type-item" sm="12" md="6" lg="6" xl="3" v-for="(item, index) in vipPermissionArr"
          :key="index">
          <div :class="['item-inner', item.vipTypeClass]">
            <div class="item-icon">
              <img :src="item.itemIcon" alt="">
            </div>
            <div class="item-title">{{item.vipName}}</div>
            <div class="cost-info">
              <span class="price">{{item.vipPrice}}<em>{{$t("vipPrivilegePriceUnit")}}/{{item.vipDuration}}</em></span>
            </div>
            <div class="original-price">
              <span v-if="index">¥{{index == 1 ? 1990 : 98000}}<em>{{$t("vipPrivilegePriceUnit")}}/{{item.vipDuration}}</em></span>
            </div>
            <ul class="item-list-wrap">
              <li :class="res.useFlag === '1' ? 'has' : ''" v-for="(res, i) in item.infoList" :key="i">
                <i></i>
                <div class="txt">
                  <span>{{res.resourceName}}{{res.resourceQuantity != 0 ?"：":""}} </span>
                  <span>{{ res.resourceQuantity != 0 ? (res.resourceQuantity != -1 ?res.resourceQuantity+res.resourceUnit+(res.cycleTime?"/"+res.cycleTime:''):'') : '' }}{{res.resourceDescribe?(res.cycleTime?",":'')+res.resourceDescribe:''}}</span>
                  <!-- <strong v-if="res.smallTips">{{res.smallTips}}</strong> -->
                </div>
              </li>
            </ul>
            <div :class="{'join-btn': true, 'is-join': userId >= item.vipId && index==0}" @click="joinVip(item)">
              <img v-if="item.vipTypeClass === 'senior-user'"
                src="@/../public/icon/vipPrivilege/senior-user-join-btn-bg@2x.png" alt="">
              <img v-else-if="item.vipTypeClass === 'quarter-user' || item.vipTypeClass === 'annual-user'"
                src="@/../public/icon/vipPrivilege/quarter-user-join-btn-bg@2x.png" alt="">
                <!-- 4 => 老会员 de9de2f006e145a29d52dfadda295353 => 运营商 所有VIP套餐均为已获得，后面套的三元运算就是判断会员是否开通对应的VIP套餐 -->
              <span>{{roleId == '4' || roleId == 'de9de2f006e145a29d52dfadda295353' ? $t("vipPrivilegeObtained") : userId >= item.vipId ? $t("vipPrivilegeObtained") : $t("vipPrivilegeActivateNow")}}</span>
            </div>
            <div v-if="item.vipDescribe !== ''" class="label-tip">{{item.vipDescribe}}</div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="content-wrap">
      <div class="programme-items">
        <div class="floor-title">{{$t("vipPrivilegeVIPSchemeZone")}}</div>
        <b-row>
          <b-col class="item" sm="12" md="6" lg="4" xl="3" v-for="(item, index) in programmeItems" :key="index">
            <div class="item-inner">
              <div class="item-pic" @click="to3DDetail(item)">
                <img :src="item.imgPath" :alt="item.worksName">
              </div>
              <div class="item-info">
                <div class="item-name" @click="to3DDetail(item)">{{item.worksName}}</div>
                <div class="item-params-wrap">
                  <div class="left-wrap">
                    <span>{{item.hourseType}}</span>
                    <span>{{item.styleName}}</span>
                    <span>{{item.measureArea}}</span>
                  </div>
                  <div class="right-wrap">
                    <i class="el-icon-view">{{item.collectionNumber}}</i>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="more-btn" @click="more3DProgramme">{{$t("vipPrivilegeMoreOptions")}}</div>
      </div>

      <div class="model">
        <div class="floor-title">{{$t("vipPrivilegeVIPModelZone")}}</div>
        <b-row>
          <b-col class="item" sm="12" md="6" lg="4" xl="3" v-for="(item, index) in modelItems" :key="index">
            <div class="item-inner">
              <div class="item-pic" @click="to3DModelDetail(item)">
                <img :src="item.coverUri" :alt="item.modelName">
                <i class="collection" @click="collection3DModel(item)">
                  <img v-if="item.followFlag" src="@/../public/icon/vipPrivilege/is-collection@2x.png" alt="">
                  <img v-else src="@/../public/icon/vipPrivilege/collection@2x.png" alt="">
                </i>
              </div>
              <div class="item-info">
                <div class="item-name" @click="to3DModelDetail(item)">{{item.modelName}}</div>
                <div class="item-params-wrap">
                  <div class="left-wrap">
                    <span>ID:{{item.modelId}}</span>
                  </div>
                  <div class="right-wrap">
                    <i class="el-icon-view">{{item.viewTotal}}</i>
                    <i class="el-icon-download">{{item.downloadTotal}}</i>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="more-btn" @click="more3DModel">{{$t("vipPrivilegeMoreModels")}}</div>
      </div>
      <div class="common-problem">
        <div class="floor-title">{{$t("vipPrivilegeCommonProblem")}}</div>
        <b-row>
          <b-col class="faq-item" sm="12" md="12" lg="12" xl="6" v-for="(item, index) in commonProblems" :key="index">
            <div class="faq-item-inner">
              <div class="question" title="">{{item.question}}</div>
              <div class="answer">
                <span>{{$t("vipPrivilegeAnswer")}}：</span>
                <p>{{item.answer}}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <Footer></Footer>
  </b-container>



</template>

<script>
  import NavBar from "@/components/Navbar.vue";
  import Footer from "@/components/Footer.vue";
  import dump from "@/util/dump";
  export default {
    components: {
      NavBar,
      Footer
    },
    data() {
      return {
        iconArr: [{
            icon: require("@/../public/icon/vipPrivilege/icon-model-download@2x.png"),
            iconTitle: this.$t("vipPrivilegeModelUnlimitedDownload")
          },
          {
            icon: require("@/../public/icon/vipPrivilege/icon-programme@2x.png"),
            iconTitle: this.$t("vipPrivilegeUnlimitedCreationOfSolutions")
          },
          {
            icon: require("@/../public/icon/vipPrivilege/icon-720deg-VR@2x.png"),
            iconTitle: this.$t("vipPrivilege720DegVRDesign")
          },
          {
            icon: require("@/../public/icon/vipPrivilege/icon-supply-price@2x.png"),
            iconTitle: this.$t("vipPrivilegeFactoryPriceDirectSupply")
          },
          {
            icon: require("@/../public/icon/vipPrivilege/icon-settlement@2x.png"),
            iconTitle: this.$t("vipPrivilegeCommissionSettlementSystem")
          },
          {
            icon: require("@/../public/icon/vipPrivilege/icon-customer-service@2x.png"),
            iconTitle: this.$t("vipPrivilegeFactoryExclusiveCustomerService")
          },
          {
            icon: require("@/../public/icon/vipPrivilege/icon-sub-account-number@2x.png"),
            iconTitle: this.$t("vipPrivilegeSubAccountManagement")
          },
          {
            icon: require("@/../public/icon/vipPrivilege/icon-release@2x.png"),
            iconTitle: this.$t("vipPrivilegeProductUploadAndRelease")
          }
        ],
        vipPermissionArr: null,
        userId: null,
        programmeItems: null,
        modelItems: [{
            pic: require("@/../public/icon/vipPrivilege/20210708161125.png"),
            name: "新中式客厅茶几沙发单椅",
            modelId: "123456",
            views: "123",
            downloads: "1",
            isCollection: false
          },
          {
            pic: require("@/../public/icon/vipPrivilege/20210708161125.png"),
            name: "新中式客厅茶几沙发单椅",
            modelId: "123456",
            views: "123",
            downloads: "1",
            isCollection: false
          },
          {
            pic: require("@/../public/icon/vipPrivilege/20210708161125.png"),
            name: "新中式客厅茶几沙发单椅",
            modelId: "123456",
            views: "123",
            downloads: "1",
            isCollection: false
          },
          {
            pic: require("@/../public/icon/vipPrivilege/20210708161125.png"),
            name: "新中式客厅茶几沙发单椅",
            modelId: "123456",
            views: "123",
            downloads: "1",
            isCollection: false
          },
          {
            pic: require("@/../public/icon/vipPrivilege/20210708161125.png"),
            name: "新中式客厅茶几沙发单椅",
            modelId: "123456",
            views: "123",
            downloads: "1",
            isCollection: false
          },
          {
            pic: require("@/../public/icon/vipPrivilege/20210708161125.png"),
            name: "新中式客厅茶几沙发单椅",
            modelId: "123456",
            views: "123",
            downloads: "1",
            isCollection: false
          },
          {
            pic: require("@/../public/icon/vipPrivilege/20210708161125.png"),
            name: "新中式客厅茶几沙发单椅",
            modelId: "123456",
            views: "123",
            downloads: "1",
            isCollection: false
          },
          {
            pic: require("@/../public/icon/vipPrivilege/20210708161125.png"),
            name: "新中式客厅茶几沙发单椅",
            modelId: "123456",
            views: "123",
            downloads: "1",
            isCollection: false
          }
        ],
        commonProblems: [{
            question: "1、开通的VIP服务是立即生效的吗？",
            answer: "是的，开通后即可享受相应的VIP身份和权益。"
          },
          {
            question: "2、开通VIP后能退款吗？",
            answer: "开通VIP后，系统会自动开放相关的权益和时长，不能接受中途退款，开通前请注意查看权益详情。"
          },
          {
            question: "3、开通了普通会员，是否可以升级为城市合伙人？",
            answer: "可以的，开通普通会员后，会员权益以及时长即刻生效，如需升级城市合伙人，则需要重新开通城市合伙人，权益以及时长则会覆盖。"
          },
          {
            question: "4、开通VIP是否包含e币？",
            answer: "不含e币，但可以享受VIP里所包含的权益。城市合伙人可以使用所有的权益，下载3D模型和使用二维方案无限制；普通会员下载3D模型和使用二维方案有数量限制，超出则需使用e币购买。"
          },
          {
            question: "5、VIP的续费规则是什么？",
            answer: "时限累加制。例如您2021年6月30日VIP到期，在此日期前续费1年，则到期时间为2022年6月29日。"
          },
          {
            question: "6、充值的e币如何使用？充值后有哪些限制？",
            answer: "e币是用于下载3D模型和使用二维方案的虚拟货币，使用没有时间限制，用完为止。一旦充值成功，不接受任何形式的退款，请充值时认真选择您需要的金额！"
          }
        ],
        token: ""
      }
    },
    created() {
      this.init();
      // VIP方案专区
      this.getDesign3D();
      // VIP模型专区
      this.getModeList();
    },
    methods: {
      init() {
        this.token = localStorage.getItem("token");
        this.userId = Number(localStorage.getItem("vipId"));
        this.roleId = localStorage.getItem("role_id");
        this.$api.vipAllPlan({}).then(res => {
          if (res.status === 100) {
            this.vipPermissionArr = res.data;
            this.vipPermissionArr.forEach((item, index) => {
              switch (index) {
                case 0:
                  this.$set(item, "vipTypeClass", "register-user")
                  this.$set(item, "itemIcon", require(
                    "@/../public/icon/vipPrivilege/register-user-icon@2x.png"))
                  break;
                case 1:
                  this.$set(item, "vipTypeClass", "quarter-user")
                  this.$set(item, "itemIcon", require("@/../public/icon/vipPrivilege/quarter-user@2x.png"))
                  break;
                case 2:
                  this.$set(item, "vipTypeClass", "annual-user")
                  this.$set(item, "itemIcon", require("@/../public/icon/vipPrivilege/annual-user@2x.png"))
                  break;
                case 3:
                  this.$set(item, "vipTypeClass", "senior-user")
                  this.$set(item, "itemIcon", require("@/../public/icon/vipPrivilege/senior-user@2x.png"))
                  break;
              }
            })
          }
        }).catch(err => console.error(err));
      },
      // 获取3维方案
      getDesign3D() {
        const that = this;
        that.$api.design3D({
          currentPage: 1,
          pageSize: 8
        }).then(res => {
          // that.designs3dList = [];
          that.programmeItems = res.data.list;

        })
      },
      // 跳转到3维方案详情
      to3DDetail(item) {
        item.type = 2;
        item.link = item.threeDUrl;
        dump.link(item);
      },
      // 更多3维方案
      more3DProgramme() {
        let data = {
          type: 3,
          link: "/threeDimensional"
        };
        dump.link(data);
      },
      // 获取3D模型
      getModeList() {
        const that = this;
        that.$api.modeList({
          pageNum: 1,
          pageSize: 8
        }).then(res => {
          // console.log(res);
          res.data.list.forEach((item) => {
            let imgs = item.img.split(',');
            item.coverUri = imgs[0];
          })
          that.modelItems = res.data.list;
          // console.log(res.data.list)
        })
      },
      // 3D模型收藏
      collection3DModel(item) {
        const that = this;
        if (!dump.checkLogin()) {
          return false;
        }
        let followFlag = 1;
        if (item.followFlag == 1) {
          followFlag = 0;
        }
        that.$api.collection({
          collection_type: 3,
          project_id: item.modelId
        }).then(res => {
          // console.log(res);
          if (res.status == 100) {
            item.followFlag = followFlag;
          }
        })
      },
      // 跳转到3D模型详情
      to3DModelDetail(item) {
        item.type = 3
        item.link = "/model3Ddetail";
        item.params = {
          id: item.modelId
        }
        dump.link(item);
      },
      // 更多3D模型
      more3DModel(){
        const params = {
          type: 3,
          link: "/tMode"
        }
        dump.link(params);
      },
      // 立即开通VIP
      joinVip(item){
        // alert(`item.vipId:${item.vipId}, this.userId:${this.userId}`)
        if(this.userId >= item.vipId || this.roleId == '4' || this.roleId == 'de9de2f006e145a29d52dfadda295353'){
          return false;
        }
        
        if (!dump.checkLogin()) {
          return false;
        }
        
        const params = {
          type: 1,
          link: "/pay/vipPaymentMethod",
          params: {
            vipId: item.vipId,
            vipPrice: item.vipPrice,
            vipName: item.vipName
          }
        }
        dump.link(params);
      },
      

    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/view/vip-privilege/vipPrivilege";
</style>
